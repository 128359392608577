<template>
    <div class="wrapper">
        <div class="box">
            <img class="tu" src="https://static.yihu365.cn/img/h5Img/assets/img/zhongq.jpg" alt="">
            <div class="box1" ref="qrCodeUrl">
            </div>
        </div>

    </div>
</template>
<script>

    import QRCode from 'qrcodejs2'
    export default {
        name: "autumn",
        methods :{
            creatQrCode(id) {
                let that=this;
                var qrcode = new QRCode(this.$refs.qrCodeUrl, {
                    text: `https://mi.yihu365.cn/one?userId=${id}`, // 需要转换为二维码的内容
                    width: 100,
                    height: 100,
                    colorDark: '#000000',
                    colorLight: '#ffffff',
                    correctLevel: QRCode.CorrectLevel.H
                })
            },
        },
        mounted () {
            const userId = this.$route.query.userId
            this.creatQrCode(userId);
        }
    }
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
    .box{
        .tu{
            width: 100%;
        }
        position: relative;

    }
    .box1{
        width: 200px;
        height: 200px;
        /*border:1px solid red;*/
        position: absolute;
        left:100px;
        top:730px;
    }
    .qrcode{
        display: inline-block;
        img {
            width: 132px;
            height: 132px;
            background-color: #fff; //设置白色背景色
            padding: 6px; // 利用padding的特性，挤出白边
            box-sizing: border-box;
        }
    }
</style>
