import { render } from "./autumn.vue?vue&type=template&id=46bc5a2f&scoped=true"
import script from "./autumn.vue?vue&type=script&lang=js"
export * from "./autumn.vue?vue&type=script&lang=js"

import "./autumn.vue?vue&type=style&index=0&id=46bc5a2f&rel=stylesheet%2Fscss&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-46bc5a2f"
/* hot reload */
if (module.hot) {
  script.__hmrId = "46bc5a2f"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('46bc5a2f', script)) {
    api.reload('46bc5a2f', script)
  }
  
  module.hot.accept("./autumn.vue?vue&type=template&id=46bc5a2f&scoped=true", () => {
    api.rerender('46bc5a2f', render)
  })

}

script.__file = "src/views/static/about/autumn.vue"

export default script